import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import './App.css';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import asurion from '../images/asurion-mobile.png';
import logo from '../images/logo.png';
import banner from '../images/banner-bg.jpg';
import man from '../images/man.png';
import bike from '../images/bike.png';
import dog from '../images/dog.png';
import alwayson from '../images/icon-alwayson.png';
import simple from '../images/icon-simple.png';
import helpful from '../images/icon-helpful.png';
import star from '../images/star.png';
import whiteLogo from '../images/logo-white.png';
import seal1 from '../images/seal1.png';
import seal2 from '../images/seal2.png';
import seal3 from '../images/seal3.png';
import downArrow from '../images/down-arrow.png';
import upArrow from '../images/up-arrow.png';
import delivery from '../images/delivery.png';
import service from '../images/service.png';
import resolve from '../images/resolve.png';
import replace from '../images/replace.png';
import spinner from '../images/spinner.gif';


import TagManager from 'react-gtm-module';
 
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_TAG
}
 
TagManager.initialize(tagManagerArgs);

const queryString = require('query-string');
const queries = queryString.parse(window.location.search);



function App() {
  const [ firstName, setFirstName] = useState('');
  const [ lastName, setLastName] = useState('');
  const [ device, setDevice] = useState('');
  const [ email, setEmail] = useState('');
  const [ keepUpdate, setKeepUpdate] = useState(false);

  const [ showConfirm, setShowConfirm] = useState(false);
  const [ showThankYou, setShowThankYou] = useState(false);
  const [ showWelcome, setShowWelcome] = useState(queries.confirm);

  const [ showCaptcha, setShowCaptcha] = useState(false);
  const [ showSpinner, setShowSpinner] = useState(false);
  const [ validated, setValidated] = useState(false);
  const [ showAcc1, setShowAcc1] = useState(false);
  const [ showAcc2, setShowAcc2] = useState(false);
  const [ showAcc3, setShowAcc3] = useState(false);
  const [ showAcc4, setShowAcc4] = useState(false);

  const recaptchaRef = React.createRef();
  const recaptcha = process.env.REACT_APP_RECAPTCHA;

  const options = [
    { value: 'Apple iPhone 12', label: 'Apple iPhone 12' },
    { value: 'Apple iPhone 12 Pro', label: 'Apple iPhone 12 Pro' },
    { value: 'Apple iPhone 12 Pro Max', label: 'Apple iPhone 12 Pro Max' },
    { value: 'Apple iPhone 11', label: 'Apple iPhone 11' },
    { value: 'Apple iPhone 11 Pro', label: 'Apple iPhone 11 Pro' },
    { value: 'Apple iPhone 11 Pro Max', label: 'Apple iPhone 11 Pro Max' },
    { value: 'Apple iPhone XR', label: 'Apple iPhone XR' },
    { value: 'Apple iPhone XS', label: 'Apple iPhone XS' },
    { value: 'Apple iPhone XS Max', label: 'Apple iPhone XS Max' },
    { value: 'Apple iPhone X', label: 'Apple iPhone X' },
    { value: 'Apple iPhone 8', label: 'Apple iPhone 8' },
    { value: 'Apple iPhone 8 Plus', label: 'Apple iPhone 8 Plus' },
    { value: 'Apple iPhone SE', label: 'Apple iPhone SE' },
    { value: 'Samsung Galaxy S20', label: 'Samsung Galaxy S20' },
    { value: 'Samsung Galaxy S20+', label: 'Samsung Galaxy S20+' },
    { value: 'Samsung Galaxy S10', label: 'Samsung Galaxy S10' },
    { value: 'Samsung Galaxy S10+', label: 'Samsung Galaxy S10+' },
    { value: 'Samsung Galaxy S10e', label: 'Samsung Galaxy S10e' },
    { value: 'Samsung Galaxy S9', label: 'Samsung Galaxy S9' },
    { value: 'Samsung Galaxy S9+', label: 'Samsung Galaxy S9+' },
    { value: 'Samsung Galaxy S8', label: 'Samsung Galaxy S8' },
    { value: 'Samsung Galaxy S8+', label: 'Samsung Galaxy S8+' },
    { value: 'Samsung Galaxy Note 20', label: 'Samsung Galaxy Note 20' },
    { value: 'Samsung Galaxy Note 20 Ultra', label: 'Samsung Galaxy Note 20 Ultra' },
    { value: 'Samsung Galaxy Note 10', label: 'Samsung Galaxy Note 10' },
    { value: 'Samsung Galaxy Note 10+', label: 'Samsung Galaxy Note 10+' },
    { value: 'Samsung Galaxy Note 9', label: 'Samsung Galaxy Note 9' },
    { value: 'Samsung Galaxy Note 8', label: 'Samsung Galaxy Note 8' },
    { value: 'Google Pixel 5', label: 'Google Pixel 5' },
    { value: 'Google Pixel 5 XL', label: 'Google Pixel 5 XL' },
    { value: 'Google Pixel 4a', label: 'Google Pixel 4a' },
    { value: 'Google Pixel 4', label: 'Google Pixel 4' },
    { value: 'Google Pixel 4 XL', label: 'Google Pixel 4 XL' },
    { value: 'Google Pixel 3a', label: 'Google Pixel 3a' },
    { value: 'Google Pixel 3a XL', label: 'Google Pixel 3a XL' },
    { value: 'Google Pixel 3', label: 'Google Pixel 3' },
    { value: 'Google Pixel 3 XL', label: 'Google Pixel 3 XL' },
    { value: 'Google Pixel 2', label: 'Google Pixel 2' },
    { value: 'Google Pixel 2 XL', label: 'Google Pixel 2 XL' },
    { value: 'Other', label: 'Other' },
  ]

  useEffect(() => {
    console.log(queries);
    if(queries.confirm){
      queries.confirm=true;
      if(queries.code){
        var welcomePayLoad={
          'code': queries.code
        }
        axios.post(process.env.REACT_APP_API_URL+'/api/confirm',welcomePayLoad,{headers:{
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        }}).then(function (response) {
          //setShowWelcome(false);
          //window.location.replace("/");
  
          console.log(response);
        }).catch(function (response) {
            console.log(response);
        });
        
      }
    }else{
      queries.confirm=false;
    }
  });
  

  const closeConfirm = () => setShowConfirm(false);
  const closeThankYou = () => {
    setShowThankYou(false);
    window.location.reload(false);
  }
  const closeWelcome = () => {
    console.log("welcome");
    console.log(queries.code);
    setShowWelcome(false);

    window.location.replace('/');
  }



  const changeValue = (name, event) => {
    switch(name) {
      case 'firstName':
        setFirstName(event.target.value);
        return;
      case 'lastName':
        setLastName(event.target.value);
        return;
      case 'email':
        setEmail(event.target.value);
        return;
    }
  };

  const changeRecaptcha = (value) => {
    console.log(value);
    setShowCaptcha(false);
  };

  const changeDevice = (event) => {
    setDevice(event.value);
  }

  const changeUpdate = (event) => {
    setKeepUpdate(event.target.checked)
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }else{
      event.preventDefault();
      setShowConfirm(true);
    }
 
  }

  const showAcc = (name) => {
    switch(name) {
      case 'acc1':
        setShowAcc1(true);
        return;
      case 'acc2':
        setShowAcc2(true);
        return;
      case 'acc3':
        setShowAcc3(true);
        return;
      case 'acc4':
        setShowAcc4(true);
        return;
    }
  }

  const hideAcc = (name) => {
    switch(name) {
      case 'acc1':
        setShowAcc1(false);
        return;
      case 'acc2':
        setShowAcc2(false);
        return;
      case 'acc3':
        setShowAcc3(false);
        return;
      case 'acc4':
        setShowAcc4(false);
        return;
    }
  }

  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY
  }

  const handleForm = (event) => {
      event.preventDefault();

      const recaptchaValue = recaptchaRef.current.getValue();
      if(!recaptchaValue){
        console.log("no value");
        setShowCaptcha(true);
        return;
      }else{
        console.log(recaptchaValue);
      }

      var payload={
        'email': email,
        'firstName': firstName,
        'lastName': lastName,
        'device': device
      }

      if(keepUpdate) {
        payload.keepUpdate="yes";
      }else{
        payload.keepUpdate="no";
      }
      console.log(payload);

      setShowSpinner(true);
      axios.post(process.env.REACT_APP_API_URL+'/api/create',payload,{
        headers: headers
      }).then(function (response) {
        setShowConfirm(false);
        response=response.data;
        console.log(response);
        setShowSpinner(false);
        setEmail('');
        setFirstName('');
        setLastName('');
        setDevice('');
        setShowThankYou(true);
      }).catch(function (response) {
          console.log(response);
      });
  };
  


  return (
    <Container className="App" fluid>
      <Row className="header">
        <a href="https://www.asurion.com.au/"><img src={logo} className="App-logo" alt="logo" /></a>
      </Row>
      <Row className="banner">
        <img src={banner} loading="lazy" />
        <div className="float-banner">
         
          <img src={asurion} loading="lazy" />
     
          <p className="float-slogan">
          Experience improved 
mobile care for less
          </p>
          <p className="float-body">
          Finally, an all-in-one plan that answers your mobile needs!
          </p>
          <AnchorLink href='#form-section' className="anchor-btn">Register now</AnchorLink>
        </div>
      </Row>
      <Row className="covers">
        <p className="covers-title">
        All the care and support for your mobile with Asurion Mobile<sup>+</sup>
        </p>
        <Row>
          <Col xl={3} lg={2} className="covers-left"><img src={bike} /></Col>
          <Col xl={6} lg={8} className="covers-body">
            <Row>
              <Col lg={6} sm={6}>
                <div className="block">
                <img src={service} loading="lazy" />
                  <div className="block-title">$49 screen replacement service</div>
                  <SlideDown closed={!showAcc1}><div className="block-body slidedown">
                  An easy, low-cost and quality service. Our convenient screen replacement will quickly get you up and running again.  </div>
                  </SlideDown>
                  <div className="block-img">
                    {showAcc1?<img src={upArrow} className="up-arrow" onClick={() => hideAcc('acc1')}/>:
                    <img src={downArrow} className="down-arrow" onClick={() => showAcc('acc1')}/>}
                  </div>
                  
                </div>
              </Col>
              <Col lg={6} sm={6}>
                <div className="block right-block">
                  <img src={resolve} loading="lazy" />
                  <div className="block-title">Asurion Experts will resolve your mobile questions</div>
                  <SlideDown closed={!showAcc2}><div className="block-body slidedown">
                  Spend less time searching for answers. Our experts can help you get the most out of your mobile from set up to troubleshooting.  </div>
                  </SlideDown>
                  <div className="block-img">
                    {showAcc2?<img src={upArrow} className="up-arrow" onClick={() => hideAcc('acc2')}/>:
                    <img src={downArrow} className="down-arrow" onClick={() => showAcc('acc2')}/>}
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={6}>
            <div className="block">
              <img src={replace} loading="lazy" />
              <div className="block-title">Next-day mobile replacement
without the hassle</div>
      

                  <SlideDown closed={!showAcc3}><div className="block-body slidedown">
                  Processing your request is quick and simple. Enjoy a next-day replacement in metro areas without the long wait times.  </div>
                  </SlideDown>
                  <div className="block-img">
                    {showAcc3?<img src={upArrow} className="up-arrow" onClick={() => hideAcc('acc3')}/>:
                    <img src={downArrow} className="down-arrow" onClick={() => showAcc('acc3')}/>}
                  </div>
            </div>

            
            </Col>
            <Col lg={6} sm={6}>
            <div className="block right-block">
              <img src={delivery} loading="lazy" />
              <div className="block-title">Complementary delivery to your doorstep</div>
            
            
              <SlideDown closed={!showAcc4}><div className="block-body slidedown">
              Never be without your mobile. No matter where you live, we will deliver a replacement device straight to your door. </div>
                  </SlideDown>
                  <div className="block-img">
                    {showAcc4?<img src={upArrow} className="up-arrow" onClick={() => hideAcc('acc4')}/>:
                    <img src={downArrow} className="down-arrow" onClick={() => showAcc('acc4')}/>}
                  </div>
            </div>
            </Col>
            </Row>
          </Col>
          <Col xl={3} lg={2} className="covers-right"><img src={dog} loading="lazy" /></Col>
        </Row>
      </Row>

      <Row className="form" id="form-section">
          <Col lg={5} className="form-left">
            <img src={man} loading="lazy" className="App-logo" alt="logo" />
          </Col>
          <Col lg={6}>
            <div className="form-title">Asurion Mobile<sup>+</sup> is launching in Australia! </div>
            <div className="form-subtitle">Provide us your details to be the first to experience this service. By pre-registering, you'll be eligible for a free screen replacement once you sign up.</div>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Row>
              <Col lg={6} sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" required value={firstName} placeholder="John" onChange={(event) => {changeValue('firstName', event)}}  />
                  <Form.Control.Feedback type="invalid">First Name is required.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" required value={lastName} placeholder="Smith" onChange={(event) => {changeValue('lastName', event)}} />
                  <Form.Control.Feedback type="invalid">Last Name is required.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" required value={email} placeholder="johnsmith@gmail.com"   onChange={(event) => {changeValue('email', event)}}  />
                  <Form.Control.Feedback type="invalid">Email is required and in correct format.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Device make / model</Form.Label>
    
                  <Select options={options} value={options.find(obj => obj.value === device)} onChange={(event) => {changeDevice(event)}} placeholder="Apple iPhone 12 Pro Max"/>

                  <Form.Control.Feedback type="invalid">Make / Model is required.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="tnc">
              <Form.Check 
                
                feedback="You must agree before submitting."
              >
                        <Form.Check.Input type="checkbox" required />
                        <Form.Check.Label>I agree to the <a href="https://www.asurion.com.au/eng/terms-of-use/" target="_blank">Terms of Use</a> and <a href="https://www.asurion.com.au/eng/privacy-policy/" target="_blank">Privacy Policy</a></Form.Check.Label>

                </Form.Check>
            </Row>
            <Row className="tnc">
              <Form.Check checked={keepUpdate} 
               onChange={(event) => {changeUpdate(event)}} 
                label='Keep me up to date on exclusive offers, products and news' 
              />
            </Row>
            <Row className="submit-btn">
            <button type="submit">Register now</button>
            </Row>
            </Form>
          </Col>
          <Col log={1}></Col>
      </Row>

      <Row className="who">
        <Col lg={12}>
        <h5>What does Asurion Mobile<sup>+</sup> stand for?</h5>
        </Col>
        
        <Col lg={12}>
          
        <Row>
        <Col lg={2}></Col>
        <Col lg={8}>
          <Row>
          <Col lg={4} md={4} className="who-block">
              <img src={simple} loading="lazy" className="App-logo" alt="logo" />
              <p><font className="who-title">Surprisingly simple.</font><br/>
An easy way to get the most out of your tech.</p>
            </Col>
            <Col lg={4} md={4} className="who-block">
              <img src={helpful} loading="lazy" className="App-logo" alt="logo" />
              <p><font className="who-title">Actually helpful. </font><br/>
Trusted by millions of customers globally.</p>
            </Col>
            <Col lg={4} md={4} className="who-block">
              <img src={alwayson} loading="lazy" className="App-logo" alt="logo" />
              <p><font className="who-title">Always on.</font><br/>
Here to keep you 
connected at all times.</p>
            </Col>
          </Row>
        </Col>
        <Col lg={2}></Col>
        </Row>
        </Col>
      </Row>


      <Row className="review">
        <Col lg={12}>
        <Row className="review-title">
        <h5>Over 14 million highly rated reviews globally</h5>
        </Row>
        
        <Row className="review-title">
          <h6>We've been helping people to get the most out of their tech for 20 years.</h6>
        </Row>

        <Row>
          <Col lg={2}></Col>
          <Col lg={8}>
            <Row>
          <Col lg={4} md={4}>
            <div className="review-block">
              <p>Amazon</p>
              <p><img loading="lazy" src={star} /></p>
              <p>4.6 stars | 28,000+ Reviews</p>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="review-block">
              <p>Google</p>
              <p><img loading="lazy" src={star} /></p>
              <p>4.7 stars | 12,000+ Reviews</p>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="review-block">
              <p>Trustpilot</p>
              <p><img loading="lazy" src={star} /></p>
              <p>4.4 stars | 49,000+ Reviews</p>
            </div>
          </Col>
          </Row>
          </Col>
          <Col lg={2}></Col>
        </Row>
        </Col>
      </Row>

      <Row className="footer">
        <p>
          <img loading="lazy" src={whiteLogo} />
        </p>
        <p className="seals">
          <img loading="lazy" src={seal1} />
          <img loading="lazy" src={seal2} />
          <img loading="lazy" src={seal3} />
        </p>
        <div className="footer-section">
          <div className="copyright">© Asurion 1992–2020. All Rights Reserved.</div>
          <div className="footer-links">
          <a href="https://www.asurion.com.au/sitemap">Site Map</a>
          <a href="https://www.asurion.com.au/eng/privacy-policy/">Privacy Policy</a>
          <a href="https://www.asurion.com.au/eng/terms-of-use/">Term of Use</a>
        </div>
        
        </div>
      </Row>

      <Modal show={showConfirm} onHide={closeConfirm} dialogClassName="confirm-modal">
        <Modal.Body>
          <p className="confirm-title">Confirm</p>
          <p className="confirm-body">By clicking Submit, you are confirming that your details are correct. Continue to sign up with Asurion Mobile<sup>+</sup>?</p>
          <Row className="confirm-form">
            <Col lg={6}>
              <div className="confirm-label">First Name</div>
              <div className="confirm-box">{firstName}</div>
            </Col>
            <Col lg={6}>
              <div className="confirm-label">Last Name</div>
              <div className="confirm-box">{lastName}</div>
            </Col>
            <Col lg={6}>
              <div className="confirm-label">Email Address</div>
              <div className="confirm-box">{email}</div>
            </Col>
            <Col lg={6}>
              <div className="confirm-label">Device make / model</div>
              <div className="confirm-box">{device}</div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
            <ReCAPTCHA ref={recaptchaRef} sitekey={recaptcha} onChange={changeRecaptcha} />
            {showCaptcha && <div className="error">Please verify you are humann. </div> }
            </Col>
            <Col lg={6} className="confirm-btn-group">
            <Button variant="secondary" className="confirm-cancel-btn" onClick={closeConfirm}>
              Cancel
            </Button>
            <Button variant="primary" className="confirm-ok-btn" onClick={handleForm}>
              Submit {showSpinner && <img src={spinner} className="spinner"/>}
            </Button>
            </Col>
          </Row>

        </Modal.Body>
      </Modal>

      <Modal show={showThankYou} onHide={closeThankYou} dialogClassName="thankyou-modal">
        <Modal.Body>
          <p className="confirm-title">Thank You!</p>
          <p className="confirm-body">We have sent you an email.
Please confirm your email
address 
to get started!</p>
                      
          <Row>
            <Col lg={12}>
            <Button variant="secondary" className="thankyou-btn" onClick={closeThankYou}>
              Got It
            </Button>
            </Col>
          </Row>

        </Modal.Body>
      </Modal>

      <Modal show={showWelcome} onHide={closeWelcome} dialogClassName="welcome-modal" backdrop="static">
        <Modal.Body>
          <p className="confirm-title">Welcome!</p>
          <p className="confirm-body">Your email is confirmed! We have sent you a welcome email.</p>
                      
          <Row>
            <Col lg={12}>
            <Button variant="secondary" className="thankyou-btn" onClick={closeWelcome}>
              Got It
            </Button>
            </Col>
          </Row>

        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default App;
